import * as React from "react";

import { graphql } from "gatsby";

import { sbEditable } from "@storyblok/storyblok-editable";
import useStoryblok from "../lib/storyblok";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import DynamicComponent from "../components/dynamicComponent/DynamicComponent";

const NotFoundPage = ({ data, location }) => {

  let story = data.storyblokEntry;
  story = useStoryblok(story, location);
 
  const components = story.content.body.map(blok => {
    return (<DynamicComponent blok={blok} key={blok._uid} />)
  });
  
  return (
    <Layout>

      <div {...sbEditable(story.content)}>
        <Seo title="404 not found" />
        {components}
      </div>
    </Layout>
  );
  
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPageQuery {
    storyblokEntry(full_slug: {eq: "404"}) {
      content
      name
    }
  }
`; 
